import * as _ from 'lodash';
import memoize from 'memoize-one';
import React, {memo, useCallback, useEffect, useMemo} from 'react';

import {
  overrideLineColors,
  overrideLineTitles,
  overrideLineWidths,
  overrideMarks,
} from '../../util/plotHelpers/chart';
import {getLinesFromData} from '../../util/plotHelpers/getLinesFromData';
import {RunSetQuery} from '../../util/queryTypes';
import {useIsInLoadedReportContext} from './../../pages/ReportPage/LoadedReportContext';
import {useRampFlagLineConstructionProfiling} from './../../util/rampFeatureFlags';
import {ConfigWrapper} from './Config';
import {useDerivedLinesConfiguration} from './config/useDerivedLinesConfiguration';
import {GraphWrapper} from './Graph';
import {usePanelConfigContext} from './PanelConfigContext';
import {usePanelData, usePostProcessedData} from './PanelDataContext';
import {usePanelTimeContext} from './PanelTimeContext';
import {usePanelZoom} from './PanelZoomContext';
import {RunsLinePlotPanelProps} from './types';
import {usePanelRunsData} from './usePanelRunsData';
import {useSamplingLineProfile} from './useSamplingLineProfile';

type PanelRunsLinePlotProps = RunsLinePlotPanelProps & {
  runSets: RunSetQuery[];
};

// History points are ALWAYS stored in ascending `_step` order,
// and sampling must always be done in a contiguous `_step` range.
// For monotonically increasing x-axis metrics, we want to do a re-sampling to ensure
// that we don't sample a `_step` range that has out-of-range x-axis values.
// This function calculates the range of `_step` values in which we should re-sample.
// We find the largest `_step` value for which the x-axis value is less than the minimum (left extreme of x-axis)
// and the smallest `_step` value for which the x-axis value is greater than the maximum (right extreme of x-axis).
// This should give us a `_step` range for which all the x-axis values are in the desired range.

export const PanelRunsLinePlot = memo(function PanelRunsLinePlot(
  props: PanelRunsLinePlotProps
) {
  const {expressionKeys, isSingleRun} = usePanelConfigContext();
  const {timeFactor} = usePanelTimeContext();
  const isInReport = useIsInLoadedReportContext();
  const lineProfilingEnabled = useRampFlagLineConstructionProfiling(
    props.pageQuery.entityName
  );
  const {config: runsLinePlotConfig} = props;

  const {handleSetHistoryData} = usePanelData();
  const {xDomainQuery} = usePanelZoom();

  const {data: rawData, loading} = usePanelRunsData(
    runsLinePlotConfig,
    props.pageQuery,
    xDomainQuery
  );

  const data = usePostProcessedData(rawData, runsLinePlotConfig);

  /**
   * When the data changes, we need to update the history data in the context. The reason is because for non-_step x-axis metrics we need to be able to convert the zoom coordinates (expressed in x-values that relate to the shown x-axis metric) back to _step so that we can feed them into the API. Avoiding circular dependencies in this is tricky, so to simplify the amount of business logic determining WHEN a new query gets made, we just syndicate each new data state up to the context so we can read it when we need it.
   */
  useEffect(() => {
    handleSetHistoryData(data, runsLinePlotConfig.xAxis ?? '');
  }, [data, handleSetHistoryData, runsLinePlotConfig.xAxis]);

  /**
   * When deriving the lines to show the run data on the plot we need to merge configuration data from multiple sources in order to draw the lines correctly.
   */
  const derivedLinesConfiguration = useDerivedLinesConfiguration({
    customRunColors: props.customRunColors,
    customRunNames: props.customRunNames,
    runsLinePlotConfig,
    runSets: props.runSets,
    zoomTimestep: timeFactor,
    semanticLegendSettings: props.semanticLegendSettings ?? {},
  });

  // We need to memoize this per component instance, rather than globally.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memGetLinesFromData = useCallback(
    memoize(getLinesFromData, (a: any[], b: any[]) => {
      // uncomment to see why getLinesFromData is being called
      // console.log(
      //   'PanelRunsLinePlot memoize',
      //   a[0] === b[0],
      //   a[1] === b[1],
      //   _.isEqual(a[2], b[2])
      // );
      // console.log(difference(a[2], b[2]));
      // This memoized comparison must be kept in sync with getLinesFromData.
      // Please let Shawn know if you need to change this.
      return a[0] === b[0] && a[1] === b[1] && _.isEqual(a[2], b[2]);
    }),
    [getLinesFromData]
  );

  const profiledGetLinesFromData = useSamplingLineProfile(
    memGetLinesFromData,
    {
      isInReport,
    },
    false, // debug mode
    // default assignment works for undefined but doesn't default null
    lineProfilingEnabled ? undefined : null
  );

  const [lines] = profiledGetLinesFromData(
    data.filtered,
    data.histories,
    derivedLinesConfiguration,
    {
      expressionKeys,
    }
  );
  const linesWithTitleOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideSeriesTitles == null) {
      return lines;
    }
    return overrideLineTitles(
      lines,
      runsLinePlotConfig.overrideSeriesTitles,
      !isSingleRun
    );
  }, [lines, runsLinePlotConfig.overrideSeriesTitles, isSingleRun]);

  const linesWithColorOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideColors == null) {
      return linesWithTitleOverride;
    }
    return overrideLineColors(
      linesWithTitleOverride,
      runsLinePlotConfig.overrideColors,
      !isSingleRun
    );
  }, [linesWithTitleOverride, runsLinePlotConfig.overrideColors, isSingleRun]);

  const linesWithMarkOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideMarks == null) {
      return linesWithColorOverride;
    }
    return overrideMarks(
      linesWithColorOverride,
      runsLinePlotConfig.overrideMarks,
      !isSingleRun
    );
  }, [linesWithColorOverride, runsLinePlotConfig.overrideMarks, isSingleRun]);

  const linesWithWidthOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideLineWidths == null) {
      return linesWithMarkOverride;
    }
    return overrideLineWidths(
      linesWithMarkOverride,
      runsLinePlotConfig.overrideLineWidths,
      !isSingleRun
    );
  }, [
    linesWithMarkOverride,
    runsLinePlotConfig.overrideLineWidths,
    isSingleRun,
  ]);

  return props.configMode ? (
    <ConfigWrapper
      {...props}
      data={data}
      lines={linesWithWidthOverride}
      lineCount={linesWithWidthOverride.length}
    />
  ) : (
    <GraphWrapper
      {...props}
      data={data}
      lineCount={linesWithWidthOverride.length}
      lines={linesWithWidthOverride}
      loading={loading}
      runNameTruncationType={props.runSets?.[0]?.runNameTruncationType}
    />
  );
});
